import React, {Component} from "react"
import {Form} from 'react-formio';

class Contact extends Component {

    render() {
        return <div className="page-bottom">
            <div className="container">
                <div className="row">
                    {/*<div className="col-lg-12">
                        <div className="contact">
                            <div className="map-wrapper">
                                <div className="map-canvas"
                                     data-zoom="15"
                                     data-lat="48.1946808"
                                     data-lng="16.3143718"
                                     data-type="roadmap"
                                     data-hue="#ffc400"
                                     data-title="Beapp"
                                     data-icon-path="assets/images/marker-green.png"
                                     data-content="Linkomat GmbH<br/>Goldschlagstraße 110/30<br/>1150 Wien<br/><br/><a>max.matschiner ät linkom.at</a>">
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="item">
                                    <i className="fa fa-location-arrow"/>
                                    <div className="txt">
                                        <span>Linkomat GmbH<br/>Steingasse 16<br/>4300 St. Valentin <br/><br/>Goldschlagstraße 110/30<br/>1150 Wien</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-phone"></i>
                                    <div className="txt">
                                        <span>+43 664 564 40 55</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-envelope"></i>
                                    <div className="txt">
                                        <span>max.matschiner ät linkom.at</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>*/}
                    <div className="col-lg-12">
                        <div className="center-heading">
                            <h2 className="section-title">Contact us if you have a question or to book a demo!</h2>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="contact-bottom">
                            <div className="row">

                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <h5 className="margin-bottom-30">Contact</h5>
                                    <div className="contact-text">
                                        <p>Please contact us for further information! </p>
                                        <p>We help you both with technical questions as well as pricing and legal questions.</p>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12">
                                    <div className="contact-form">
                                        <Form src="https://ybnfoavqkmmlqoi.form.io/contact"/>
                                        {/*<div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <input type="text" placeholder="Name" />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <input type="text" placeholder="E-Mail" />
                                            </div>
                                            <div className="col-lg-12">
                                                <textarea placeholder="NACHRICHT"></textarea>
                                            </div>
                                            <div className="col-lg-12">
                                                <button className="btn-primary-line">SENDEN</button>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    }
}

export default Contact;