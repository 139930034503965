import React, {Component} from 'react';
/*

<!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-39202958-18"></script>
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-39202958-18');
    </script>
 */
class Startseite extends Component {
    render(){
        return <div className="welcome-area" id="welcome">
            <div className="right-bg">
                <img src="assets/images/photos/header3.jpg" className="img-fluid float-right" alt=""/>
            </div>
            <div className="header-hexagon-bg">
                <img src="assets/images/header-bg.svg" className="img-fluid" alt=""/>
            </div>

            <div className="header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <br/>
                            <br/>
                            <br/>
                            <p>
                                We are the tax technology division of the <a href="//essentiaglobalservices.com" target={"_blank"}>Essentia Global Services</a> group.
                                We develop VAT information, compliance management and automation tools to make your life easier. Just scroll down to see our products.
                            </p>
                            <p>
                                If you were looking for Essentia Global Services' other VAT outsourcing, training, consultancy or recruitment services please click&nbsp;
                                <a href="//essentiaglobalservices.com" target={"_blank"}>here</a>.

                            </p>
                            <div>
                                <div >

                                    <a className={"btn-primary-line"} href={"/#4tools"}>Explore</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    }
}
export default Startseite;