import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Startseite from "./Startseite"
import Header from "./Header"
import "./max.css"
import Contact from "./Contact"
import Funktionen from "./Funktionen"
import Impressum from "./Impressum";
import BasicMap from "./worldmap";

class App extends Component {

    render() {
        return (
            <>
                <Router onUpdate={() => window.scrollTo(0, 0)}>
                    <>
                        <Route path="/" component={Header}/>
                        <section className="page">
                            <Route path="/" exact component={Startseite}/>


                            <Route path="/funktionen" component={Funktionen}/>
                            <Route path="/" exact component={Funktionen}/>

                            {/*
                            <Route path="/preise" component={Preise}/>
                            <Route path="/" exact component={Preise}/>
                               */}
                            <Route path="/contact" component={Contact}/>
                            <Route path="/" exact component={Contact}/>

                            <Route path="/imprint" component={Impressum}/>
                            <Route path="/worldmap" component={BasicMap}/>
                        </section>
                    </>
                </Router>
            </>
        );
    }
}

export default App;
