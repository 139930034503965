import React from "react";
import {VectorMap} from "react-jvectormap";

const ActiveCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "HR",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",

    "NO",
    "CH",

    "CA",
    "SG",
    "RU",
    "AU",
    "NZ"
];
const AutomatedCountries =[
    "DE","GB","FR","IE","NL"
]

const seriesData = {
    ...ActiveCountries.reduce((obj, country) => {
        obj[country] = "rgba(110,161,154,0.36)";
        return obj
    }, {}),
    ...AutomatedCountries.reduce((obj, country) => {
        obj[country] = "#6EA19A";
        return obj
    }, {}),
}

export default class BasicMap extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{width: 500, height: 500}}>
            <VectorMap map={'world_mill'}
                       series={{
                           regions: [{
                               values: seriesData,
                               attribute: 'fill'
                           }]
                       }}
                       backgroundColor="#eee"

                       ref="map"
                       containerStyle={{
                           width: '100%',
                           height: '100%'
                       }}
                       containerClassName="map"
            />
        </div>
    }
}