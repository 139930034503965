import React, {Component} from 'react';
import logo from "./essentia-tax.svg"

class Header extends Component {
    componentDidMount = ()=>{
       /* if($('.menu-trigger').length){
            $(".menu-trigger").on('click', function() {
                $(this).toggleClass('active');
                $('.header-area .nav').slideToggle(200);
            });
        }*/
    }
    render() {

        return <header className={"header-area "+ (this.props.location.pathname!=="/"?"header-white":"")} >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">
                            <a href="/" className="logo">
                                <img src={logo} alt="Hexagon"/>
                                <br />

                                <span id={"group_assoc"}>
                                    a part of <a target={"_blank"} href="//essentiaglobalservices.com">
                                Essentia Global Services</a>
                                </span>
                            </a>


                            <ul className="nav">
                                <li><a href="/">Homepage</a></li>

                                <li className="submenu">
                                    <a href={"/#4tools"} style={{cursor: "pointer"}}>Tools</a>
                                    <ul>
                                        <li><a href="/#vatomator">VATomator</a></li>
                                        <li><a href="/#digitalsales">Digital Sales Calculator</a></li>
                                        <li><a href="/#vatschool">VAT school</a></li>
                                        <li><a href="/#vatomatordetail">VATomator Details</a></li>
                                    </ul>
                                </li>
                                <li><a href="/contact">Demo</a></li>
                                <li><a href="/contact" className="btn-nav-line"><span>Contact</span></a></li>
                            </ul>
                            <a className='menu-trigger'>
                                <span>Menu</span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    }
}
export default Header
