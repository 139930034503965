import React, {Component} from 'react';

import {FaCalendar, FaCalendarAlt, FaEnvelope, FaFileInvoice, FaGlobe, FaLock, FaUsers, FaWind} from "react-icons/fa";
import dashboard from "./dashboard.png"
import dashboardFrame from "./vatomator_framed.png"
import vatsearchFramed from "./evatserach_framed.png"
import digitalsalesFramed from "./digitalsales_framed.png"
import trafficlights from "./trafficlightsmacro_framed.png"
import reminders from "./reminders_framed.png"
import archive from "./archive_framed.png"
import collaboration from "./collaboration_framed.png"
import fileupload from "./fileupload_framed.png"
import submission from "./submission_framed.png"
import BasicMap from "./worldmap";

class Funktionen extends Component {
    render() {
        return (
            <>


                <section className="section" id={"4tools"}>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">Our 4 tools that make your life easier</h2>
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <div className="center-text">

                                </div>
                            </div>
                        </div>


                        <div className="row">


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                                <a href="#vatomator" className="features-small-item ">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-users"></i>
                                    </div>
                                    <h5 className="features-title">VATomator Management tool</h5>
                                    <p>Is an easy to use VAT compliance workflow management tool that does not allow you to miss any deadline.</p>

                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                                <a href="#vatomator" className="features-small-item ">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-users"></i>
                                    </div>
                                    <h5 className="features-title">VATomator Submission Tool</h5>
                                    <p>Automatically submits your declarations to the relevant tax authorities with a standardized UI and no possibility for typos.</p>

                                </a>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                                <a href="#vatomator" className="features-small-item ">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-users"></i>
                                    </div>
                                    <h5 className="features-title">VATomator Archival Tool</h5>
                                    <p>Lets you store all the historic filings and documents in one secure and sustainable place.</p>

                                </a>
                            </div>


                            {/*<div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                                <a href="#vat-search" className="features-small-item">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-search"></i>
                                    </div>
                                    <h5 className="features-title">VAT-Search</h5>
                                    <p>Find and validate your business partners' VAT ID numbers based only on names and addresses.</p>
                                </a>
                            </div>*/}

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                                <a href="#digitalsales" className="features-small-item">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-credit-card"></i>
                                    </div>
                                    <h5 className="features-title">Digital Sales Calculator</h5>
                                    <p>Selling digital downloads? Calculate which countries will require you to register for VAT.</p>
                                </a>
                            </div>


                            {/*<div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.8s">
                                <a href="#brexitdiagnosis" className="features-small-item">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-building"></i>
                                    </div>
                                    <h5 className="features-title">Brexit Diagnosis Tool</h5>
                                    <p>Generate a report on how to Brexit proof your business based on answers to some simple online questions.</p>
                                </a>
                            </div>*/}

                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"vatomator"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        The VATomator is our international VAT compliance automation, workflow and archiving tool.
                                    </p>
                                    <p>
                                        It is built for humans and is intuitive and easy to use.
                                    </p>
                                    <p>
                                        It gives you:
                                    </p>
                                    <ul>
                                        <li>control all your VAT filings from a dashboard (on the right)</li>
                                        <li>coverage of over 35 countries</li>
                                        <li>automated prompts for upcoming deadlines and confirmation of all filings performed</li>
                                        <li>secure uploading of data via portal rather than email</li>
                                        <li>secure and unlimited archive of all your filings and work files within a structured environment</li>
                                        <li>a collaborative environment to view, edit and approve files for staff-manager or client-account interaction</li>
                                        <li>push button automated filings using Robotic Process Automation for several countries (including full compliance with UK Making-Tax-Digital)</li>
                                    </ul>
                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>More</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <img src={dashboardFrame} className="img-fluid float-left browserFrame" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="section white padding-bottom-top-60 bg-center-right" id={"vatsearch"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">

                                <img src={vatsearchFramed} className="img-fluid float-right" alt="App"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">VAT-Search</h2>
                                </div>
                                <div className="right-text">
                                    <p>
                                        Many businesses need to capture customer VAT numbers either for intra EU reporting purposes, domestic invoicing purposes or as part of
                                        anti money laundering / know your customer processes.
                                    </p>
                                    <p>
                                        Some authorities offer databases to check VAT number validity (EC VIES system) but to use these you must first have the client's VAT number,
                                        and getting that can be the most time consuming part of the process.
                                    </p>
                                    <p>
                                        VAT-Search comes at the problem from your direction:
                                        put in your customers' address and it will find their VAT number (and then automatically verify it with the corresponding tax authority).
                                    </p>
                                    <a href={"//essentia.vat-search.eu"} target={"_blank"} className={"btn-primary-line"}>Try Out for Free</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>*/}


                <section className="section white padding-bottom-top-60 bg-center-right" id={"digitalsales"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">

                                <img src={digitalsalesFramed} className="img-fluid float-left" alt="App"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the Digital Sales Calculator</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        If you sell digital download products B2C you find a maze of digital VAT regulations. Some countries want you to register for VAT, some do not;
                                        of those that do some have thresholds, some do not and the thresholds are usually set in local currencies that are fluctuating against yours.
                                        The Digital Sales Calculator will tell you where you have to register and where you do not.
                                    </p>
                                    <p>
                                        With tax rules updated monthly and currency rates updated in real time you can just insert your turnover in USD, EUR or GBP
                                        and the Digital Sales Calculator will do the rest.
                                    </p>
                                    <a href={"//vatomator.com/calculator"} target={"_blank"} className={"btn-primary-line"}>Try Out for Free</a>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

               {/* <section className="section white padding-bottom-top-60 bg-center-right" id={"brexitdiagnosis"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">

                                <img src={dashboardFrame} className="img-fluid float-right" alt="App"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the Brexit Diagnosis Tool</h2>
                                </div>
                                <div className="right-text">
                                    <p>
                                        Noone knows what Brexit brings. Brexit will have a complex impact on business' trading between the EU and UK. Our automated diagnostics tool asks you a series
                                        of
                                        questions about the business activity and based on the answers it will generate a report on key risk areas and suggested action points.
                                    </p>
                                    <a href={"//vatomator.com/calculator"} target={"_blank"} className={"btn-primary-line"}>Try Out for Free</a>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>*/}


                <section className="section white padding-bottom-top-60 bg-center-left" id={"vatschool"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VAT school - COMING SOON</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        Interactive training software that will upskill staff in VAT in a new and accessible manner while monitoring progress for managing purposes.
                                    </p>
                                    <p>
                                        It will features modules for specific countries and need-to-know topics for specific staff roles (e.g. AP / AR teams or financial controllers).
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">

                                <img src={digitalsalesFramed} className="img-fluid float-left" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                <section className="section" id="opinions">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">What the users think:</h2>
                                </div>
                            </div>
                            {/*<div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</p>
                                </div>
                            </div>
                        </div>


                        <div className="row testimonials-wrapper">

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <i className={"fa fa-user"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Sophia Bond</h3>
                                        <span>Sportliche Leitung, <br/>Turnverein St. Valentin</span>
                                        <p>VEREINfacht erleichert meine Arbeit, da ich neue Kurse leicht und interaktiv hinzufügen kann. Außerdem kann ich jederzeit übersichtliche Statistiken zu
                                            unseren Mitgliedern abrufen.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/nicole.jpgf"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Nicole Kapeller</h3>
                                        <span>Buchhaltung,<br/> Turnverein St. Valentin</span>
                                        <p>VEREINfacht erleichtert meine Arbeit, da die Zahlungen automatisch erfasst werden, die USt-VA direkt an das Finanzamt und die Endberichte direkt an den
                                            Steuerberater übermittelt werden können.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/anna.jpgf"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Anna Grasegger</h3>
                                        <span>Marketing,<br/>Turnverein St. Valentin</span>
                                        <p>VEREINfacht konnte ich direkt in unsere Vereins-Homepage integrieren um den nahtlosen Übergang von der Kundengewinnung in unser Verwaltungssystem zu
                                            gewährleisten.</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                */}
                <section className="section" id="vatomatordetail">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">VATomator DETAILS</h2>
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>
                                        Market leading functionality in VAT compliance management!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"trafficlights"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator DASHBOARD</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        As a busy tax manager with many registrations, or a busy accountant with many clients, you can have an overview of all current and pending filings with a
                                        red-amber-green "traffic light"-system to flag urgency.
                                    </p>

                                    <p>
                                        Full internal linkage so that you can immediately click through to the filings.
                                    </p>

                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <img src={trafficlights} className="img-fluid float-left browserFrame" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"coverage"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                {/*<img src="assets/images/photos/features/green-1.png" className="img-fluid float-left" alt="App" />*/}

                                <BasicMap/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator COVERAGE</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        Functionally, it covers VAT returns, European Sales Lists, Intrastats and SAF-T.
                                        It is flexible enough for extra returns (e.g. excise duty or state statistics) to be added if you need them.
                                    </p>
                                    <p>
                                        Geographically, it covers all EU Member States plus significant non EU countries such as Australia, Canada, New Zealand, Norway, Russia, Singapore and
                                        Switzerland.
                                    </p>
                                    <p>
                                        It is loaded with local rules and deadlines for all countries, even taking account of the impact of local national holidays.
                                    </p>

                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"reminders"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator REMINDERS and CONFIRMATIONS</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        The VATomator understands which deadlines you are facing in which countries and will automatically send you prompts to upload data.
                                    </p>
                                    <p>
                                        Multiple prompts can be sent to managers and staff or accountants and clients and the function can be turned off for users you do not want it.
                                    </p>
                                    <p>
                                        In addition the VATomator will automatically notify users once returns are submitted.
                                    </p>
                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <img src={reminders} className="img-fluid float-left browserFrame" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"archive"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                <img src={archive} className="img-fluid float-right browserFrame" alt="App"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator ARCHIVE</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        The VATomator stores all submissions and work files for current and historic returns in a clear and intuitive file hierarchy.
                                    </p>
                                    <p>
                                        Our file storage is GDPR compliant and securely hosted in Germany in accordance with ISO/IEC 27001.
                                    </p>
                                    <p>
                                        You can use it with confidence as your primary file storage system or a reliable backup.
                                    </p>
                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"editor"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator EDITOR</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        The live file collaboration feature enables to staff and management or accountants and clients to work together on work files queries without the
                                        risk and confusion of circulating file versions by email.
                                    </p>
                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <img src={collaboration} className="img-fluid float-left browserFrame" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section white padding-bottom-top-60 bg-center-left" id={"portal"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                <img src={fileupload} className="img-fluid float-right browserFrame" alt="App"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator PORTAL</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        Files can be up- and downloaded securely via a portal rather than generating multiple emails.
                                    </p>
                                    <p>
                                        However, if you have co-workers or clients who do not feel comfortable using a portal they can just email the VATomator and it is smart enough to take
                                        things from there.
                                    </p>
                                    <p>
                                        Other co-workers or account managers will have the same experience as if the data had been directly uploaded.
                                    </p>

                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="section white padding-bottom-top-60 bg-center-left" id={"submission"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">the VATomator AUTOMATION ROBOT</h2>
                                </div>
                                <div className="left-text">
                                    <p>
                                        Our vision is that the VATomator is a workflow tool that will become a push button automated submission tool,
                                        seamlessly, as soon as each jurisdiction allows.
                                    </p>
                                    <p>
                                        Currently the VATomator has the capability of push button automated filings in Germany, the UK (the latter being fully MTD compliant), the Netherlands, Ireland and France.
                                    </p>
                                    <p>
                                        The workflows are configured so that, as each country comes on stream, the VATomator can go from "workflow management tool" to
                                        "workflow management plus automated submission tool" with almost no change in user process or experience.
                                        Things just get steadily easier and easier!
                                    </p>

                                    <a href={"#vatomatordetail"} className={"btn-primary-line"}>Get a demo!</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <img src={submission} className="img-fluid float-left browserFrame" alt="App"/>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                <section className="parallax" data-image="assets/images/photos/parallax.jpg">
                    <div className="parallax-content">
                        <div className="container paymentmethods">
                            <div className="row">
                                <div className="offset-lg-2 col-lg-8">
                                    <div className="info">
                                        <p>These are the countries our software can do automated filings in: </p>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <i className="fa fa-credit-card"></i>
                                        <span>Kreditkarten</span>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <i className="fa fa-building"></i>
                                        <span>Banküberweisungen <br/>mit automatischem Import</span>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <FaWind className={"fa"}/>
                                        <span>EPS / SOFORT-ÜBERWEISUNGEN</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>
                */}


                <section className="parallax-counter" data-image="assets/images/photos/parallax-counter.jpg">
                    <div className="parallax-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>50</strong>
                                        <span>Happy <br/>Customers</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>1600</strong>
                                        <span>Users</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>3000</strong>
                                        <span>Filings automatically submitted</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>12000</strong>
                                        <span>Filings<br/>tracked</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*
                <section className="section white bg-center-left" id="finanzen">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">Blog</h2>
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/1.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">How to select the best channel manager for your hotel?</a>
                                    </h3>
                                    <div className="text">
                                        Mauris tellus sem, ultrices varius nisl at, convallis iaculis mauris. Sed eget sem vitae purus tempus dignissim.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/2.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">Find out where you earn most money – Hexagon statistics</a>
                                    </h3>
                                    <div className="text">
                                        Cras imperdiet faucibus sem, a dignissim urna feugiat sed. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/3.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">Earn more profits from Hexagon than high seasons!</a>
                                    </h3>
                                    <div className="text">
                                        Quisque euismod nec lacus sit amet maximus. Ut convallis sagittis lorem auctor malesuada. Morbi auctor tortor eu risus condimentum.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
            </>
        )
    }
}

export default Funktionen
