import React, {Component} from "react";

class Impressum extends Component {

    render() {
        return <div className="page-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact" style={{textAlign: "right"}}>


                            <h1>Impressum</h1>

                            <br/>
                            Essentia Tax Technologies Ltd<br/>
                            103 Cregagh Road<br/>
                            Belfast, County Antrim<br/>
                            BT6 8PY, Northern Ireland<br/><br/>

                            <i className="left">Company Number:</i> 680372<br/>
                            <i className="left">Tel.-Nr.:</i> <a href="tel:00442037133535">+44 (0) 203 713 3535</a><br/>
                            <br/><br/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Impressum
